import groupBy from "json-groupby";
import axios from "axios";
import displayRes from "@/components/displayRes";
export default {
  name: "catalogo",
  components: {
    displayRes,
  },
  props: [],
  data() {
    return {
      hei: null,
      hei1: null,
      hei2: null,
      modeDev: false,
      itemsPeriodiFields: [
        { key: "id_periodo", thClass: "d-none", tdClass: "d-none" },
        { key: "descrizione" },
      ],
      itemsPeriodi: [],
      idPeriodoScelto: 0,
      itemsAutoriFields: [
        { key: "id_autore", thClass: "d-none", tdClass: "d-none" },
        { key: "cognomenome" },
      ],
      itemsAutori: [],
      idAutoreScelto: 0,
      indexAutoreScelto: 0,
      filtroAutori: null,
      numFiltratiStr: null,
      campiFiltroAutori: ["nome", "cognome"],
      autori: [],
      itemsOpereFields: [
        { key: "id_opera", thClass: "d-none", tdClass: "d-none" },
        { key: "campounico" },
      ],
      itemsOpere: [],
      OperaScelta: {},
      numOpereAut: null,
      visuOpere: false,
      componimenti: null,
      visuComponimento: false,
      fonti: [],
      testoComponimento: "",
      loadingComp: false,
    };
  },
  computed: {},
  mounted() {
    this.getWindowHeight();
    window.addEventListener("resize", this.getWindowHeight);
    console.log("catalogo -> mounted starts");
    this.modeDev = process.env.NODE_ENV === "development";
    if (this.$store.getters.isPeriodiLoaded) {
      console.log("Periodi pronti nello store, no AXIOS");
      this.dataPrepare("per", this.$store.getters.getPeriodi);
    } else {
      this.$store.dispatch("loadPeriodi");
    }
    if (this.$store.getters.isAutoriLoaded) {
      console.log("Autori pronti nello store, no AXIOS");
      this.dataPrepare("aut", this.$store.getters.getAutori);
    } else {
      this.$store.dispatch("loadAutori");
    }
    if (this.$store.getters.isOpereLoaded) {
      console.log("Opere pronte nello store, no AXIOS");
    } else {
      this.$store.dispatch("loadOpere");
    }
    if (this.$store.getters.isFontiLoaded) {
      console.log("Fonti pronte nello store, no AXIOS");
      this.dataPrepare("fon", this.$store.getters.getFonti);
    } else {
      this.$store.dispatch("loadFonti");
    }
    this.$store.subscribe((mutation, state) => {
      console.log("STORE: rilevata mutation [" + mutation.type + "]");
      if (mutation.type === "setAutori") {
        this.dataPrepare("aut", state.autori);
      }
      if (mutation.type === "setPeriodi") {
        this.dataPrepare("per", state.periodi);
      }
      if (mutation.type === "setFonti") {
        this.dataPrepare("fon", state.fonti);
      }
    });
  },
  methods: {
    getWindowHeight() {
      this.hei = String(document.documentElement.clientHeight - 280) + "px";
      this.hei1 = String(document.documentElement.clientHeight - 426) + "px";
      this.hei2 = String(document.documentElement.clientHeight - 280) + "px";
    },
    classPeriodoScelto(item) {
      if (item.id_periodo === this.idPeriodoScelto) {
        return "menuscelto";
      } else {
        return "bg-white";
      }
    },
    classAutoreScelto(item) {
      if (item.id_autore === this.idAutoreScelto) {
        return "menuscelto";
      } else {
        return "bg-white";
      }
    },
    rimuoviFiltroPeriodi() {
      this.indexAutoreScelto = 0;
      this.idAutoreScelto = 0;
      this.itemsAutori = this.$store.getters.getAutori;
      this.idPeriodoScelto = 0;
    },
    itemsPeriodiClick(item) {
      this.indexAutoreScelto = 0;
      this.idAutoreScelto = 0;
      this.idPeriodoScelto = item.id_periodo;
      this.itemsAutori = this.$store.getters.getAutori.filter(
        (x) => x.id_periodo === item.id_periodo
      );
    },
    contaFiltratiStr(i) {
      this.indexAutoreScelto = 0;
      this.idAutoreScelto = 0;
      this.numFiltratiStr = i.length;
    },
    itemsAutoriClick(item, i) {
      this.indexAutoreScelto = i;
      this.idAutoreScelto = item.id_autore;
      this.itemsOpere = this.$store.getters.getOpere.filter(
        (x) => x.id_autore === item.id_autore
      );
      this.visuOpere = false;
      this.componimenti = null;
    },
    prepareCnote(t) {
      return t.replaceAll("<em>", '<em style="color: #00F;">');
    },
    prepareComp(t) {
      var tmp = t.split("\r\n");
      var ret = "<table>";
      var nv = 0;
      tmp.forEach((x) => {
        if (x.trim() === "" || x[0] === "#") {
          ret +=
            "<tr><td>" +
            x.replaceAll("#", "") +
            "&nbsp;</td><td class='pl-5'></td></tr>";
        } else {
          nv++;
          ret +=
            "<tr><td>" +
            x +
            "&nbsp;</td><td class='pl-5'>" +
            (nv % 5 ? "" : nv) +
            "</td></tr>";
        }
      });
      ret += "</table>";
      return ret;
    },
    itemsOpereClick(item) {
      this.loadingComp = true;
      this.operaScelta = item;
      axios
        .post("https://poesialirica.it/ws/qry-list-comp.php", {
          id_autore: this.idAutoreScelto,
          id_opera: item.id_opera,
        })
        .then(
          (res) => (
            this.componimenti = groupBy(res.data, ["id_fonte", "id_sezione"]), //eslint-disable-line
            this.visuOpere = true, //eslint-disable-line
            this.loadingComp = false //eslint-disable-line
          )
        )
        .catch((err) => console.log(err));
    },
    dataPrepare(cosa, dat) {
      if (cosa === "per") {
        this.itemsPeriodi = dat;
      }
      if (cosa === "aut") {
        this.itemsAutori = dat;
        this.numFiltratiStr = dat.length;
      }
      if (cosa === "fon") {
        this.fonti = groupBy(dat, ["id_fonte"]);
      }
    },
    romanize(num) {
      if (isNaN(num)) return NaN;
      var digits = String(+num).split(""),
        key = [
          "",
          "C",
          "CC",
          "CCC",
          "CD",
          "D",
          "DC",
          "DCC",
          "DCCC",
          "CM",
          "",
          "X",
          "XX",
          "XXX",
          "XL",
          "L",
          "LX",
          "LXX",
          "LXXX",
          "XC",
          "",
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
        ],
        roman = "",
        i = 3;
      while (i--) roman = (key[+digits.pop() + i * 10] || "") + roman;
      return Array(+digits.join("") + 1).join("M") + roman;
    },
  },
};
